import { Button } from 'antd';
import { useLARDBContext } from 'pages/Onboarding/LARDB/hooks/LARDBContext';
import { useSelector } from 'react-redux';
import classes from './styles/Reassign.module.css';

export default function PurchaseOrder() {
  const { lar_id: id } = useLARDBContext();
  const { role, token } = useSelector((s) => s.user.userData);
  const isSuperadmin = /^superadmin|PLA$/i.test(role);
  const isBroker = /broker/i.test(role);

  function handleDownload() {
    const params = new URLSearchParams({
      lar_id: id,
      jwt: token,
    });

    window.open(`${process.env.REACT_APP_API_URL}/LAR/broker_agreement/download?${params}`);
  }

  if (!isBroker && !isSuperadmin) return null;

  return (
    <>
      <b>Starport Platform Purchase Order</b>
      <div className={classes.content}>
        <div>
          <Button onClick={handleDownload}>Download</Button>
        </div>
      </div>
    </>
  );
}
