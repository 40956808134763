import { Checkbox, Col, Row, Table, Tooltip } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { DashboardAPI } from 'api';

import LenderActions from './LenderActions';

import larPlaceholderImg from 'assets/images/LAR_placeholder.svg';
import { ChevronIcon } from '../../../../assets/icons';

import styles from './LenderTable.module.css';

class Column {
  constructor(title, dataIndex, sortBy, setSortBy, sortingDirection, setSortingDirection, render, tooltip) {
    function headerCellClickHandler() {
      if (!sortBy) {
        return;
      }

      if (dataIndex !== sortBy) {
        setSortBy(dataIndex);
        return;
      }

      setSortingDirection((prev) => (prev === 'desc' ? 'asc' : 'desc'));
    }

    this.title = (
      <Tooltip title={tooltip}>
        <div onClick={headerCellClickHandler} className={styles.tableHeaderCell}>
          {title}
          {dataIndex === sortBy && <ChevronIcon direction={sortingDirection === 'desc' ? 'down' : 'up'} />}
        </div>
      </Tooltip>
    );
    this.render = render;
    this.dataIndex = dataIndex;
  }
}

export default function LenderTable() {
  const history = useHistory();

  const API = useMemo(() => new DashboardAPI(), []);

  const [data, setData] = useState({ lars: [], total_count: 0 });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [showComplete, setShowComplete] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [orderBy, setOrderBy] = useState('id');
  const [orderType, setOrderType] = useState('desc');

  function getColumn(title, key, props) {
    const render = props?.render || undefined;
    const sortByValue = props?.sorting === false ? null : orderBy;
    return new Column(title, key, sortByValue, setOrderBy, orderType, setOrderType, render, props?.tooltip);
  }

  const columns = useMemo(
    () => [
      {
        dataIndex: 'preview',
        title: '',
        render: (preview) => <img src={preview || larPlaceholderImg} width={130} height={80} alt="" />,
      },
      getColumn('Deal ID', 'id'),
      getColumn('Date Invited', 'date_invited'),
      getColumn('Purpose', 'purpose'),
      getColumn('Loan Amount', 'loan_amount', {
        render: (v) => (v ? `$${v.toLocaleString()}` : ''),
      }),
      getColumn('Type', 'type'),
      getColumn('Messages', 'chat_status'),
      {
        dataIndex: 'actions',
        render: (_, el) => <LenderActions data={el} />,
        title: '',
      },
    ],
    [orderBy, orderType],
  );

  useEffect(() => {
    setIsFetching(true);

    API.getDashboardPipelines('lender', {
      page: pagination.current,
      per_page: pagination.pageSize,
      show_complete: showComplete,
      order_by: orderBy,
      order_type: orderType,
    })
      .then((res) => setData(res))
      .catch(() => setData({ lars: [], total_count: 0 }))
      .finally(() => setIsFetching(false));
  }, [pagination, showComplete, orderBy, orderType]);

  function handleShowComplete(e) {
    setShowComplete(Number(e.target.checked));
  }

  return (
    <div className={classNames(styles.root)}>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className={styles.title}>Task list waiting for your review</h2>
        </Col>
        <Col>
          <Checkbox onChange={handleShowComplete}>Show quoted</Checkbox>
        </Col>
      </Row>
      <div className={styles.tableContainer}>
        <Table
          columns={columns}
          dataSource={data.lars}
          loading={isFetching}
          onChange={(p) => {
            setPagination((prev) => ({
              ...prev,
              ...p,
            }));
          }}
          onRow={({ id, type }) => ({
            onClick: () => history.push(`/onboarding${type === 'scenario' ? '/scenarios/' : '/live_auctions/'}${id}`),
          })}
          pagination={{
            ...pagination,
            showSizeChanger: true,
            total: data.total_count,
          }}
          rowKey={(_, i) => i}
          size="middle"
        />
      </div>
    </div>
  );
}
