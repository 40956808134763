// Icons in this file are legacy, consider using /icons/index.js instead

import Icon from '@ant-design/icons';
import globalStyles from 'constants/globalStyles';

function MailSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3 7L12 13L21 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
function VectorSvg() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1H21V22" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
}
function RepeatSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 12V9C4 8.20435 4.31607 7.44129 4.87868 6.87868C5.44129 6.31607 6.20435 6 7 6H20M20 6L17 3M20 6L17 9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 12V15C20 15.7956 19.6839 16.5587 19.1213 17.1213C18.5587 17.6839 17.7956 18 17 18H4M4 18L7 21M4 18L7 15"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function HorizontalDotsSvg() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3387:77888)">
        <path d="M0.5 24.5L0.5 0.5H24.5V24.5H0.5Z" fill="none" />
        <path
          d="M13.5 12.5C13.5 11.9477 13.0523 11.5 12.5 11.5C11.9477 11.5 11.5 11.9477 11.5 12.5C11.5 13.0523 11.9477 13.5 12.5 13.5C13.0523 13.5 13.5 13.0523 13.5 12.5Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.5 12.5C20.5 11.9477 20.0523 11.5 19.5 11.5C18.9477 11.5 18.5 11.9477 18.5 12.5C18.5 13.0523 18.9477 13.5 19.5 13.5C20.0523 13.5 20.5 13.0523 20.5 12.5Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 12.5C6.5 11.9477 6.05228 11.5 5.5 11.5C4.94772 11.5 4.5 11.9477 4.5 12.5C4.5 13.0523 4.94772 13.5 5.5 13.5C6.05228 13.5 6.5 13.0523 6.5 12.5Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3387:77888">
          <rect width="24" height="24" fill="white" transform="translate(0.5 24.5) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  );
}
function CloseRightArrowSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 12H7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 12L13 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 12L13 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 4V20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
function ChevronSvg() {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.5 4.5L6.5 7.5L9.5 4.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
function CloseRightArrowRedesignSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 12H10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 12L16 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20 12L16 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 4V20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

function NoteBigSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 20L20 13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13 20V14C13 13.7348 13.1054 13.4804 13.2929 13.2929C13.4804 13.1054 13.7348 13 14 13H20V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H13Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function MailDoubleSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 14L18 11H11C10.7348 11 10.4804 10.8946 10.2929 10.7071C10.1054 10.5196 10 10.2652 10 10V4C10 3.73478 10.1054 3.48043 10.2929 3.29289C10.4804 3.10536 10.7348 3 11 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V14Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 15V17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H6L3 21V11C3 10.7348 3.10536 10.4804 3.29289 10.2929C3.48043 10.1054 3.73478 10 4 10H6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function PhoneSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21C14.0993 20.763 10.4202 19.1065 7.65683 16.3432C4.8935 13.5798 3.23705 9.90074 3 6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function PhoneBlueSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21C14.0993 20.763 10.4202 19.1065 7.65683 16.3432C4.8935 13.5798 3.23705 9.90074 3 6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4"
        stroke="rgb(191, 191, 191)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function PhonePurpleSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21C14.0993 20.763 10.4202 19.1065 7.65683 16.3432C4.8935 13.5798 3.23705 9.90074 3 6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4"
        stroke={globalStyles.main_blue_color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function SuccessSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        fill="#F6FFED"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 12L11 14L15 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
export function CloseIcon(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.58898 7L13.0421 2.54687C13.2535 2.3359 13.3723 2.04962 13.3726 1.75099C13.3729 1.45237 13.2545 1.16587 13.0435 0.95453C12.8325 0.743185 12.5463 0.624305 12.2476 0.624041C11.949 0.623778 11.6625 0.742152 11.4512 0.953123L6.99804 5.40625L2.54492 0.953123C2.33358 0.741779 2.04693 0.623047 1.74805 0.623047C1.44916 0.623047 1.16251 0.741779 0.95117 0.953123C0.739826 1.16447 0.621094 1.45111 0.621094 1.75C0.621094 2.04888 0.739826 2.33553 0.95117 2.54687L5.4043 7L0.95117 11.4531C0.739826 11.6645 0.621094 11.9511 0.621094 12.25C0.621094 12.5489 0.739826 12.8355 0.95117 13.0469C1.16251 13.2582 1.44916 13.3769 1.74805 13.3769C2.04693 13.3769 2.33358 13.2582 2.54492 13.0469L6.99804 8.59375L11.4512 13.0469C11.6625 13.2582 11.9492 13.3769 12.248 13.3769C12.5469 13.3769 12.8336 13.2582 13.0449 13.0469C13.2563 12.8355 13.375 12.5489 13.375 12.25C13.375 11.9511 13.2563 11.6645 13.0449 11.4531L8.58898 7Z"
        fill="currentColor"
      />
    </svg>
  );
}
function SendSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3467:78011)">
        <path
          d="M5.99393 11.9953L10.0243 11.9745"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9079 11.9548L3.58382 20.0865C3.4851 20.1232 3.37792 20.1308 3.27503 20.1083C3.17213 20.0859 3.07785 20.0343 3.00338 19.9599C2.92891 19.8854 2.87739 19.7911 2.85493 19.6882C2.83248 19.5853 2.84004 19.4782 2.87671 19.3794L5.35159 11.9548L2.87671 4.53019C2.84004 4.43147 2.83248 4.32429 2.85493 4.2214C2.87739 4.1185 2.92891 4.02422 3.00338 3.94975C3.07785 3.87528 3.17213 3.82375 3.27503 3.8013C3.37792 3.77885 3.4851 3.78641 3.58382 3.82308L20.9079 11.9548Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3467:78011">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
// const ChatSvg = () => (
//     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//         <path
//             d="M3 19.9996L4.3 16.0996C3.17644 14.4379 2.76999 12.47 3.15622 10.5619C3.54244 8.65378 4.69506 6.93526 6.39977 5.72586C8.10447 4.51647 10.2453 3.89849 12.4241 3.98683C14.6029 4.07517 16.6715 4.86382 18.2453 6.20615C19.819 7.54848 20.7909 9.35313 20.9801 11.2846C21.1693 13.216 20.563 15.1428 19.2739 16.7067C17.9848 18.2706 16.1007 19.3652 13.9718 19.7869C11.8429 20.2087 9.6142 19.9289 7.7 18.9996L3 19.9996"
//             stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//         <path d="M12 12V12.01" stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//         <path d="M8 12V12.01" stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//         <path d="M16 12V12.01" stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
//     </svg>
// )
function LeftArrowSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 12L11 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 12L11 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
function ChevronLeftSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 6L9 12L15 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
function ChevronRightSvg(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9 6L15 12L9 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
function NotificationSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 5C10 4.46957 10.2107 3.96086 10.5858 3.58579C10.9609 3.21071 11.4696 3 12 3C12.5304 3 13.0391 3.21071 13.4142 3.58579C13.7893 3.96086 14 4.46957 14 5C15.1484 5.54303 16.1274 6.38833 16.8321 7.4453C17.5367 8.50227 17.9404 9.73107 18 11V14C18.0753 14.6217 18.2954 15.2171 18.6428 15.7381C18.9902 16.2592 19.4551 16.6914 20 17H4C4.54494 16.6914 5.00981 16.2592 5.35719 15.7381C5.70457 15.2171 5.92474 14.6217 6 14V11C6.05956 9.73107 6.4633 8.50227 7.16795 7.4453C7.8726 6.38833 8.85159 5.54303 10 5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 17V18C9 18.7956 9.31607 19.5587 9.87868 20.1213C10.4413 20.6839 11.2044 21 12 21C12.7956 21 13.5587 20.6839 14.1213 20.1213C14.6839 19.5587 15 18.7956 15 18V17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function ArchiveSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM12 17.5L6.5 12H10v-2h4v2h3.5L12 17.5zM5.12 5l.81-1h12l.94 1H5.12z"
        fill="rgb(191, 191, 191)"
      />
    </svg>
  );
}
function ArchiveLightGraySvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM12 17.5L6.5 12H10v-2h4v2h3.5L12 17.5zM5.12 5l.81-1h12l.94 1H5.12z"
        fill="rgb(191, 191, 191)"
      />
    </svg>
  );
}
function DownloadSvg() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 17V19C4.5 19.5304 4.71071 20.0391 5.08579 20.4142C5.46086 20.7893 5.96957 21 6.5 21H18.5C19.0304 21 19.5391 20.7893 19.9142 20.4142C20.2893 20.0391 20.5 19.5304 20.5 19V17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 11L12.5 16L17.5 11"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.5 4V16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
function CalendarSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 5H6C4.89543 5 4 5.89543 4 7V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19V7C20 5.89543 19.1046 5 18 5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path d="M16 3V7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

      <path d="M8 3V7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

      <path d="M4 11H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

      <path d="M10 16H14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />

      <path d="M12 14V18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
function LockClosedSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 11H7C5.89543 11 5 11.8954 5 13V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V13C19 11.8954 18.1046 11 17 11Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11V7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V11"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function LockOpenedSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 11H7C5.89543 11 5 11.8954 5 13V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V13C19 11.8954 18.1046 11 17 11Z"
        stroke="#8C8C8C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
        stroke="#8C8C8C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11V6C8 4.93913 8.42143 3.92172 9.17157 3.17157C9.92172 2.42143 10.9391 2 12 2C13.0609 2 14.0783 2.42143 14.8284 3.17157C15.5786 3.92172 16 4.93913 16 6"
        stroke="#8C8C8C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function EllipseSvg() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="currentColor" />
    </svg>
  );
}
function TwoMessagesSvg() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.7499 15.1667L19.4999 11.9167H11.9166C11.6293 11.9167 11.3537 11.8025 11.1506 11.5994C10.9474 11.3962 10.8333 11.1207 10.8333 10.8333V4.33333C10.8333 4.04602 10.9474 3.77047 11.1506 3.5673C11.3537 3.36414 11.6293 3.25 11.9166 3.25H21.6666C21.9539 3.25 22.2295 3.36414 22.4326 3.5673C22.6358 3.77047 22.7499 4.04602 22.7499 4.33333V15.1667Z"
        stroke="currentColor"
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M15.1667 16.25V18.4167C15.1667 18.704 15.0525 18.9796 14.8494 19.1827C14.6462 19.3859 14.3707 19.5 14.0833 19.5H6.5L3.25 22.75V11.9167C3.25 11.6294 3.36414 11.3538 3.5673 11.1507C3.77047 10.9475 4.04602 10.8334 4.33333 10.8334H6.5"
        stroke="currentColor"
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function PlaceSvg() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 15.1665C14.7949 15.1665 16.25 13.7114 16.25 11.9165C16.25 10.1216 14.7949 8.6665 13 8.6665C11.2051 8.6665 9.75 10.1216 9.75 11.9165C9.75 13.7114 11.2051 15.1665 13 15.1665Z"
        stroke="currentColor"
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1291 18.0453L14.5325 22.6418C14.1262 23.0477 13.5755 23.2757 13.0012 23.2757C12.4269 23.2757 11.8762 23.0477 11.4699 22.6418L6.87224 18.0453C5.66023 16.8332 4.83485 15.2889 4.50048 13.6078C4.16611 11.9266 4.33777 10.1841 4.99374 8.60046C5.64971 7.01686 6.76054 5.66334 8.18576 4.71105C9.61098 3.75877 11.2866 3.25049 13.0007 3.25049C14.7147 3.25049 16.3903 3.75877 17.8156 4.71105C19.2408 5.66334 20.3516 7.01686 21.0076 8.60046C21.6635 10.1841 21.8352 11.9266 21.5008 13.6078C21.1665 15.2889 20.3411 16.8332 19.1291 18.0453V18.0453Z"
        stroke="currentColor"
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ClockSvg() {
  return (
    <svg width="93" height="93" viewBox="0 0 93 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="46.5" cy="46.5" r="46.5" fill="currentColor" fillOpacity="0.1" />
      <path
        d="M46.5 78.375C64.1041 78.375 78.375 64.1041 78.375 46.5C78.375 28.8959 64.1041 14.625 46.5 14.625C28.8959 14.625 14.625 28.8959 14.625 46.5C14.625 64.1041 28.8959 78.375 46.5 78.375Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5 28.7917V46.5001L57.125 57.1251"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function MessageSvg() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 21V8C4.5 7.20435 4.81607 6.44129 5.37868 5.87868C5.94129 5.31607 6.70435 5 7.5 5H17.5C18.2956 5 19.0587 5.31607 19.6213 5.87868C20.1839 6.44129 20.5 7.20435 20.5 8V14C20.5 14.7956 20.1839 15.5587 19.6213 16.1213C19.0587 16.6839 18.2956 17 17.5 17H8.5L4.5 21Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.5 9H16.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.5 13H14.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

function TrophySvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 21H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 17V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 4H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17 4V12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12V4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 11C6.10457 11 7 10.1046 7 9C7 7.89543 6.10457 7 5 7C3.89543 7 3 7.89543 3 9C3 10.1046 3.89543 11 5 11Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 11C20.1046 11 21 10.1046 21 9C21 7.89543 20.1046 7 19 7C17.8954 7 17 7.89543 17 9C17 10.1046 17.8954 11 19 11Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function LongArrowSvg() {
  return (
    <svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.7071 8.70711C31.0976 8.31659 31.0976 7.68342 30.7071 7.2929L24.3431 0.928934C23.9526 0.53841 23.3195 0.53841 22.9289 0.928934C22.5384 1.31946 22.5384 1.95262 22.9289 2.34315L28.5858 8L22.9289 13.6569C22.5384 14.0474 22.5384 14.6805 22.9289 15.0711C23.3195 15.4616 23.9526 15.4616 24.3431 15.0711L30.7071 8.70711ZM-8.74228e-08 9L30 9L30 7L8.74228e-08 7L-8.74228e-08 9Z"
        fill="currentColor"
      />
    </svg>
  );
}
function PencilSvg() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.33301 26.6669H10.6663L24.6663 12.6669C25.3736 11.9597 25.7709 11.0004 25.7709 10.0002C25.7709 9.00005 25.3736 8.04082 24.6663 7.33357C23.9591 6.62633 22.9999 6.229 21.9997 6.229C20.9995 6.229 20.0403 6.62633 19.333 7.33357L5.33301 21.3336V26.6669Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 8.6665L23.3333 13.9998"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function PlusSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 5V19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

function MicrophoneSvg() {
  return (
    <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.118 9.102c-1.037.206-2.234 1.146-2.707 2.125-.387.802-.409 1.025-.409 4.273 0 3.247.022 3.47.408 4.273.308.64 1.177 1.509 1.817 1.817 1.123.54 2.423.54 3.546 0 .64-.308 1.509-1.177 1.817-1.817.386-.803.408-1.026.408-4.273 0-3.248-.022-3.471-.409-4.273-.338-.7-1.119-1.463-1.856-1.812-.854-.405-1.664-.502-2.615-.313m1.715 2.091c.371.164.809.605.99.997.14.303.144.381.144 3.31 0 2.942-.003 3.006-.146 3.315C21.508 19.492 20.728 20 20 20s-1.508-.508-1.821-1.185c-.141-.305-.146-.4-.165-3.108-.022-3.072.001-3.321.341-3.813a2.018 2.018 0 0 1 2.478-.701m-8.326 5.958c-.434.249-.524.494-.482 1.316.078 1.523.664 3.113 1.603 4.354 1.176 1.553 2.846 2.599 4.822 3.018l.55.117V28l-1.617.001c-1.55.001-1.627.007-1.876.15-.683.392-.653 1.406.052 1.741.203.096.692.108 4.441.108 3.749 0 4.238-.012 4.441-.108.705-.335.735-1.349.052-1.741-.249-.143-.326-.149-1.876-.15L21 28v-2.044l.55-.117c3.616-.767 6.24-3.779 6.425-7.372.042-.822-.048-1.067-.482-1.316-.321-.184-.621-.191-.952-.022-.39.199-.488.405-.554 1.168-.154 1.773-.854 3.177-2.138 4.287-.561.486-1.552 1.004-2.349 1.229-.771.218-2.232.217-3-.001-1.719-.488-3.113-1.634-3.871-3.181-.351-.716-.537-1.422-.616-2.334-.066-.763-.164-.969-.554-1.168-.331-.169-.631-.162-.952.022"
        fillRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}
function SendMessageSvg() {
  return (
    <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_3659_411234)">
        <rect width="40" height="40" rx="20" fill="var(--main_blue_color)" />
        <g clipPath="url(#clip0_3659_411234)">
          <path
            d="M13.9939 19.9944L18.0243 19.9735"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.9079 19.9548L11.5838 28.0865C11.4851 28.1232 11.3779 28.1308 11.275 28.1083C11.1721 28.0859 11.0778 28.0343 11.0034 27.9599C10.9289 27.8854 10.8774 27.7911 10.8549 27.6882C10.8325 27.5853 10.84 27.4782 10.8767 27.3794L13.3516 19.9548L10.8767 12.5302C10.84 12.4315 10.8325 12.3243 10.8549 12.2214C10.8774 12.1185 10.9289 12.0242 11.0034 11.9497C11.0778 11.8753 11.1721 11.8238 11.275 11.8013C11.3779 11.7788 11.4851 11.7864 11.5838 11.8231L28.9079 19.9548Z"
            stroke="#FDFDFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3659_411234"
          x="0"
          y="0"
          width="40"
          height="42"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3659_411234" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3659_411234" result="shape" />
        </filter>
        <clipPath id="clip0_3659_411234">
          <rect width="24" height="24" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
}
function VerticalDotsSvg() {
  return (
    <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4987 17.3327C16.2121 17.3327 16.7904 16.7357 16.7904 15.9993C16.7904 15.263 16.2121 14.666 15.4987 14.666C14.7853 14.666 14.207 15.263 14.207 15.9993C14.207 16.7357 14.7853 17.3327 15.4987 17.3327Z"
        stroke="#BFBFBF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4987 26.6667C16.2121 26.6667 16.7904 26.0697 16.7904 25.3333C16.7904 24.597 16.2121 24 15.4987 24C14.7853 24 14.207 24.597 14.207 25.3333C14.207 26.0697 14.7853 26.6667 15.4987 26.6667Z"
        stroke="#BFBFBF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4987 8.00065C16.2121 8.00065 16.7904 7.4037 16.7904 6.66732C16.7904 5.93094 16.2121 5.33398 15.4987 5.33398C14.7853 5.33398 14.207 5.93094 14.207 6.66732C14.207 7.4037 14.7853 8.00065 15.4987 8.00065Z"
        stroke="#BFBFBF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function TrashSvg() {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.5 7H20.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 11V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5 11V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.5 7L6.5 19C6.5 19.5304 6.71071 20.0391 7.08579 20.4142C7.46086 20.7893 7.96957 21 8.5 21H16.5C17.0304 21 17.5391 20.7893 17.9142 20.4142C18.2893 20.0391 18.5 19.5304 18.5 19L19.5 7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 7V4C9.5 3.73478 9.60536 3.48043 9.79289 3.29289C9.98043 3.10536 10.2348 3 10.5 3H14.5C14.7652 3 15.0196 3.10536 15.2071 3.29289C15.3946 3.48043 15.5 3.73478 15.5 4V7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function ReplySvg() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 6.5L2.5 4.5M2.5 4.5L4.5 2.5M2.5 4.5H8C8.53043 4.5 9.03914 4.71071 9.41421 5.08579C9.78929 5.46086 10 5.96957 10 6.5C10 7.03043 9.78929 7.53914 9.41421 7.91421C9.03914 8.28929 8.53043 8.5 8 8.5H7.5"
        stroke="#2951E7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function ReplyBigSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 6.5L2.5 4.5M2.5 4.5L4.5 2.5M2.5 4.5H8C8.53043 4.5 9.03914 4.71071 9.41421 5.08579C9.78929 5.46086 10 5.96957 10 6.5C10 7.03043 9.78929 7.53914 9.41421 7.91421C9.03914 8.28929 8.53043 8.5 8 8.5H7.5"
        stroke="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function ArrowBackCurvedSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
      <g>
        <rect fill="none" height="24" width="24" x="0" />
      </g>
      <g>
        <g>
          <g>
            <path
              d="M20.55,5.22l-1.39-1.68C18.88,3.21,18.47,3,18,3H6C5.53,3,5.12,3.21,4.85,3.55L3.46,5.22C3.17,5.57,3,6.01,3,6.5V19 c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6.5C21,6.01,20.83,5.57,20.55,5.22z M12,9.5l5.5,5.5H14v2h-4v-2H6.5L12,9.5z M5.12,5 l0.82-1h12l0.93,1H5.12z"
              fill="rgb(191, 191, 191)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
function FileGrayDownload() {
  return (
    <svg width="190" height="190" viewBox="0 0 190 190" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M110.833 23.75V55.4167C110.833 57.5163 111.668 59.5299 113.152 61.0146C114.637 62.4993 116.651 63.3333 118.75 63.3333H150.417"
        stroke="#BFBFBF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.584 166.25H55.4168C51.2176 166.25 47.1903 164.582 44.221 161.613C41.2516 158.643 39.5835 154.616 39.5835 150.417V39.5833C39.5835 35.3841 41.2516 31.3568 44.221 28.3875C47.1903 25.4181 51.2176 23.75 55.4168 23.75H110.834L150.417 63.3333V150.417C150.417 154.616 148.749 158.643 145.779 161.613C142.81 164.582 138.783 166.25 134.584 166.25Z"
        stroke="#BFBFBF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M95 87.083V134.583" stroke="#BFBFBF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M71.25 110.833L95 134.583L118.75 110.833"
        stroke="#BFBFBF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function ShareSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6C15 7.65685 16.3431 9 18 9Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 21C19.6569 21 21 19.6569 21 18C21 16.3431 19.6569 15 18 15C16.3431 15 15 16.3431 15 18C15 19.6569 16.3431 21 18 21Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.69922 10.7L15.2992 7.29999"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.69922 13.3L15.2992 16.7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function FileDownloadSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 11V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 14L12 17L15 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function FileDownloadIcon(props) {
  return <Icon component={FileDownloadSvg} {...props} />;
}
export function ShareIcon(props) {
  return <Icon component={ShareSvg} {...props} />;
}
export function MailIcon(props) {
  return <Icon component={MailSvg} {...props} />;
}
export function RepeatIcon(props) {
  return <Icon component={RepeatSvg} {...props} />;
}
export function HorizontalDotsIcon(props) {
  return <Icon component={HorizontalDotsSvg} {...props} />;
}
export function CloseRightArrowIcon(props) {
  return <Icon component={CloseRightArrowSvg} {...props} />;
}
export function CloseRightArrowRedesignIcon(props) {
  return <Icon component={CloseRightArrowRedesignSvg} {...props} />;
}
export function NoteBigIcon(props) {
  return <Icon component={NoteBigSvg} {...props} />;
}
export function MailDoubleIcon(props) {
  return <Icon component={MailDoubleSvg} {...props} />;
}
export function PhoneIcon(props) {
  return <Icon component={PhoneSvg} {...props} />;
}
export function PhoneBlueIcon(props) {
  return <Icon component={PhoneBlueSvg} {...props} />;
}
export function PhonePurpleIcon(props) {
  return <Icon component={PhonePurpleSvg} {...props} />;
}
export function SuccessIcon(props) {
  return <Icon component={SuccessSvg} {...props} />;
}
export function SendIcon(props) {
  return <Icon component={SendSvg} {...props} />;
}
export function LeftArrowIcon(props) {
  return <Icon component={LeftArrowSvg} {...props} />;
}
export function ChevronLeftIcon(props) {
  return <Icon component={ChevronLeftSvg} {...props} />;
}
export function ChevronRightIcon(props) {
  return <ChevronRightSvg {...props} />;
}
export function NotificationIcon(props) {
  return <Icon component={NotificationSvg} {...props} />;
}
export function ArchiveIcon(props) {
  return <Icon component={ArchiveSvg} {...props} />;
}
export function ArchiveLightGrayIcon(props) {
  return <Icon component={ArchiveLightGraySvg} {...props} />;
}
export function DownloadIcon(props) {
  return <Icon component={DownloadSvg} {...props} />;
}
export function LockOpened(props) {
  return <Icon component={LockOpenedSvg} {...props} />;
}
export function LockClosed(props) {
  return <Icon component={LockClosedSvg} {...props} />;
}
export function EllipseIcon(props) {
  return <Icon component={EllipseSvg} {...props} />;
}
export function CalendarIcon(props) {
  return <Icon component={CalendarSvg} {...props} />;
}
export function TwoMessagesIcon(props) {
  return <Icon component={TwoMessagesSvg} {...props} />;
}
export function PlaceIcon(props) {
  return <Icon component={PlaceSvg} {...props} />;
}
export function FileDownload(props) {
  return <Icon component={FileDownloadSvg} {...props} />;
}
export function FileGrayDownloadIcon(props) {
  return <Icon component={FileGrayDownload} {...props} />;
}
export function ClockIcon(props) {
  return <Icon component={ClockSvg} {...props} />;
}
export function MessageIcon(props) {
  return <Icon component={MessageSvg} {...props} />;
}
export function TrophyIcon(props) {
  return <Icon component={TrophySvg} {...props} />;
}
export function LongArrowIcon(props) {
  return <Icon component={LongArrowSvg} {...props} />;
}
export function PencilIcon(props) {
  return <Icon component={PencilSvg} {...props} />;
}
export function PlusIcon(props) {
  return <Icon component={PlusSvg} {...props} />;
}
export function MicrophoneIcon(props) {
  return <Icon component={MicrophoneSvg} {...props} />;
}
export function SendMessageIcon(props) {
  return <Icon component={SendMessageSvg} {...props} />;
}
export function VerticalDotsIcon(props) {
  return <Icon component={VerticalDotsSvg} {...props} />;
}
export function TrashIcon(props) {
  return <Icon component={TrashSvg} {...props} />;
}
export function ReplyIcon(props) {
  return <Icon component={ReplySvg} {...props} />;
}
export function ReplyBigIcon(props) {
  return <Icon component={ReplyBigSvg} {...props} />;
}
export function ArrowBackCurvedIcon(props) {
  return <Icon component={ArrowBackCurvedSvg} {...props} />;
}
export function ChevronIcon(props) {
  return <Icon component={ChevronSvg} {...props} />;
}
export function VectorIcon(props) {
  return <Icon component={VectorSvg} {...props} />;
}

export function FileUploadIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ color: '#8C8C8C' }}
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 11V17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 14L12 11L15 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function EyeIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99967 11.6666C10.9201 11.6666 11.6663 10.9204 11.6663 9.99992C11.6663 9.07944 10.9201 8.33325 9.99967 8.33325C9.0792 8.33325 8.33301 9.07944 8.33301 9.99992C8.33301 10.9204 9.0792 11.6666 9.99967 11.6666Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3337 10.0001C16.1112 13.8892 13.3337 15.8334 10.0003 15.8334C6.66699 15.8334 3.88949 13.8892 1.66699 10.0001C3.88949 6.11092 6.66699 4.16675 10.0003 4.16675C13.3337 4.16675 16.1112 6.11092 18.3337 10.0001Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function FullScreenIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ color: '#595959' }}
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 16V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V16"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ZoomInIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={{ color: 'white' }}
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 6H9V9H6V11H9V14H11V11H14V9H11V6Z" fill="currentColor" />
      <path
        d="M10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18C11.846 18 13.543 17.365 14.897 16.312L19.293 20.708L20.707 19.294L16.311 14.898C17.365 13.543 18 11.846 18 10C18 5.589 14.411 2 10 2ZM10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ZoomOutIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{ color: '#FDFDFF' }}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 9H14V11H6V9Z" fill="currentColor" />
      <path
        d="M10 18C11.846 18 13.543 17.365 14.897 16.312L19.293 20.708L20.707 19.294L16.311 14.898C17.365 13.543 18 11.846 18 10C18 5.589 14.411 2 10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18ZM10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function CloseWhiteIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ color: '#FDFDFF' }}
      {...props}
    >
      <path
        d="M13.5909 12L18.0441 7.54687C18.2554 7.3359 18.3743 7.04962 18.3745 6.75099C18.3748 6.45237 18.2564 6.16587 18.0455 5.95453C17.8345 5.74319 17.5482 5.62431 17.2496 5.62404C16.951 5.62378 16.6645 5.74215 16.4531 5.95312L12 10.4062L7.54687 5.95312C7.33553 5.74178 7.04888 5.62305 6.75 5.62305C6.45111 5.62305 6.16447 5.74178 5.95312 5.95312C5.74178 6.16447 5.62305 6.45111 5.62305 6.75C5.62305 7.04888 5.74178 7.33553 5.95312 7.54687L10.4062 12L5.95312 16.4531C5.74178 16.6645 5.62305 16.9511 5.62305 17.25C5.62305 17.5489 5.74178 17.8355 5.95312 18.0469C6.16447 18.2582 6.45111 18.3769 6.75 18.3769C7.04888 18.3769 7.33553 18.2582 7.54687 18.0469L12 13.5937L16.4531 18.0469C16.6645 18.2582 16.9511 18.3769 17.25 18.3769C17.5489 18.3769 17.8355 18.2582 18.0469 18.0469C18.2582 17.8355 18.3769 17.5489 18.3769 17.25C18.3769 16.9511 18.2582 16.6645 18.0469 16.4531L13.5909 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function LoanApplicationIcon(props) {
  return (
    <svg width="184" height="29" viewBox="0 0 184 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 0L12.2451 6.90983H19.5106L13.6327 11.1803L15.8779 18.0902L10 13.8197L4.12215 18.0902L6.36729 11.1803L0.489435 6.90983H7.75486L10 0Z"
        fill="#FF0A00"
      />
      <path
        d="M42.5 0L44.7451 6.90983H52.0106L46.1327 11.1803L48.3779 18.0902L42.5 13.8197L36.6221 18.0902L38.8673 11.1803L32.9894 6.90983H40.2549L42.5 0Z"
        fill="#FF0A00"
      />
      <path
        d="M75 0L77.2451 6.90983H84.5106L78.6327 11.1803L80.8779 18.0902L75 13.8197L69.1221 18.0902L71.3673 11.1803L65.4894 6.90983H72.7549L75 0Z"
        fill="#FF0A00"
      />
      <line y1="28" x2="184" y2="28" stroke="#008FF8" strokeWidth="2" />
    </svg>
  );
}

export function ThanksForRegistrationIcon() {
  return (
    <svg width="84" height="48" viewBox="0 0 84 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="23.5" fill="#40A9FF" stroke="#40A9FF" />
      <circle cx="60" cy="24" r="24" fill="#2951E7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.99 14C18.47 14 14 18.48 14 24C14 29.52 18.47 34 23.99 34C29.52 34 34 29.52 34 24C34 18.48 29.52 14 23.99 14ZM24 32C19.58 32 16 28.42 16 24C16 19.58 19.58 16 24 16C28.42 16 32 19.58 32 24C32 28.42 28.42 32 24 32ZM20.1144 21.1129L21.4135 20.3629L24.0385 24.9096L29.2706 24.9719L29.2361 26.4121L23.1144 26.3091L20.1144 21.1129Z"
        fill="white"
      />
      <path
        d="M52.6414 23.2314L51.3594 24.7694L58.1284 30.4084L68.7694 17.6414L67.2314 16.3594L57.8724 27.5924L52.6414 23.2314Z"
        fill="white"
      />
    </svg>
  );
}

export function InfoCircleIcon({ fill = 'none' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke="rgba(255, 255, 255, 0.75)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8H12.01"
        stroke="rgba(255, 255, 255, 0.75)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 12H12V16H13"
        stroke="rgba(255, 255, 255, 0.75)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronDownBigIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.293 9.29303L12 13.586L7.70697 9.29303L6.29297 10.707L12 16.414L17.707 10.707L16.293 9.29303Z"
        fill="black"
      />
    </svg>
  );
}

export function CircleXIcon(props) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14 24.5C19.799 24.5 24.5 19.799 24.5 14C24.5 8.20101 19.799 3.5 14 3.5C8.20101 3.5 3.5 8.20101 3.5 14C3.5 19.799 8.20101 24.5 14 24.5Z"
        fill="#FFF1F0"
        stroke="#FFCCC7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3334 11.6666L11.6667 16.3333M11.6667 11.6666L16.3334 16.3333L11.6667 11.6666Z"
        stroke="#F5222D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function UploadIcon(props) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.5919 15.3201H13.5919V9.32007H16.5919L12.5919 4.32007L8.59192 9.32007H11.5919V15.3201Z" />
      <path d="M20.5919 18.3201H4.59192V11.3201H2.59192V18.3201C2.59192 19.4231 3.48892 20.3201 4.59192 20.3201H20.5919C21.6949 20.3201 22.5919 19.4231 22.5919 18.3201V11.3201H20.5919V18.3201Z" />
    </svg>
  );
}

export function HouseIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2 14H14" stroke="#8C8C8C" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.3335 14V4.66667L8.66683 2V14" stroke="#8C8C8C" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.6665 13.9993V7.33268L8.6665 4.66602" stroke="#8C8C8C" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 6V6.00667" stroke="#8C8C8C" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 8V8.00667" stroke="#8C8C8C" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 10V10.0067" stroke="#8C8C8C" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 12V12.0067" stroke="#8C8C8C" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function SeparatorVerticalIcon(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clip-path="url(#clip0_9825_32882)">
        <path d="M12 4V20" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8 8L4 12L8 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 16L20 12L16 8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_9825_32882">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
