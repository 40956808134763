import { Button, Space } from 'antd';
import { LARAPI } from 'api';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import DelayArchivePopup from '../../Dashboard/DelayArchivePopup/DelayArchivePopup';

import { BROKERS } from 'constants/roles';
import { ARCHIVE, PRE_AUCTION } from 'constants/larApp';

import { useLARDBContext } from 'pages/Onboarding/LARDB/hooks/LARDBContext';
import Box from 'components/UI/Box';
import Tooltip from 'components/UI/Tooltip';
import Contacts from './Contacts';
import Control from './Control';
import PurchaseOrder from './PurchaseOrder';
import Reassign from './Reassign';
import Reject from './Reject';
import Scheduling from './Scheduling/Scheduling';

import styles from './styles/Settings.module.css';

export default function Settings() {
  const { lar_id: id, lar_status, schedule } = useLARDBContext();
  const API = useMemo(() => new LARAPI(id), [id]);
  const role = useSelector((s) => s.user.userData?.role);
  const [archivedData, setArchivedData] = useState({});
  const [delayArchiveDate, setDelayArchiveDate] = useState({});
  const [isArchiveDelayOpen, toggleIsArchiveDelayOpen] = useReducer((s) => !s, false);

  useEffect(() => {
    API.getArchivedData().then((res) => {
      setArchivedData(res);
    });
  }, [API]);

  useEffect(() => {
    API.getDelayArchiveDate().then((res) => {
      setDelayArchiveDate(res);
    });
  }, [API]);

  const handleArchiveDelay = () => {
    toggleIsArchiveDelayOpen();
  };

  const showTooltip = lar_status === PRE_AUCTION && !schedule;

  const isBroker = useMemo(() => /^superadmin$|broker/i.test(role), [role]);
  const isPLA = useMemo(() => /^superadmin$|pla/i.test(role), [role]);

  return (
    <div className={styles.root}>
      {showTooltip && BROKERS.includes(role) && (
        <Tooltip
          icon={<InfoCircleOutlined style={{ color: '#FAAD14', fontSize: '20px' }} />}
          text="This deal is pending personal loan advisor review. We will let you know when you are able to schedule an auction."
          type="warn"
        />
      )}
      {archivedData.archive_time && lar_status === ARCHIVE && (
        <Box>This deal was archived on {moment(archivedData.archive_time).format('MMM DD, YYYY')}</Box>
      )}
      <div className={styles.grid}>
        {(isBroker || isPLA) && /Active|Quote Selection/i.test(lar_status) && delayArchiveDate.archive_time && (
          <>
            <b>This deal will be archived on</b>
            <Space>
              {moment(delayArchiveDate.archive_time).format('MMM DD, YYYY')}
              {delayArchiveDate && (
                <Button className="btn_darkblue" onClick={toggleIsArchiveDelayOpen}>
                  Delay Archive
                </Button>
              )}
            </Space>
          </>
        )}
        <Scheduling />
        <Contacts API={API} />
        <Control API={API} />
        <Reassign />
        {!/Pending Review/i.test(lar_status) && <PurchaseOrder />}
        <Reject />
      </div>
      <DelayArchivePopup
        id={id}
        isOpen={isArchiveDelayOpen}
        onSubmit={handleArchiveDelay}
        onCancel={toggleIsArchiveDelayOpen}
      />
    </div>
  );
}
