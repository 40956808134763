import API from '../API';
import DDCentreAPI from './DDCentreAPI';

const crutch = [
  {
    snake: 'project_costs',
    backend: 'Project costs',
  },
  {
    snake: 'origination_fees',
    backend: 'Origination Fees',
  },
  {
    snake: 'services',
    backend: 'Services',
  },
  {
    snake: 'taxes',
    backend: 'Taxes and Other Government Fees',
  },
  {
    snake: 'prepaid',
    backend: 'Prepaid',
  },
  {
    snake: 'initial_escrow',
    backend: 'Initial Escrow at Closing',
  },
  {
    snake: 'monthly',
    backend: 'Recurring Monthly Fees',
  },
];

export default class LARAPI {
  #id = 0;

  constructor(id) {
    this.#id = id;
    this.DDCentre = new DDCentreAPI(id);
  }

  async pass(reason, additionalInfo, alreadySeen, doesNotMeetCriteria, moreDetails, notCompetitive, tooBusy) {
    return API().post('/LAR/bid_process/lender/pass_auction', {
      additional_info: additionalInfo,
      already_seen: alreadySeen,
      does_not_meet_criteria: doesNotMeetCriteria,
      lar_id: this.#id,
      more_details: moreDetails,
      not_competitive: notCompetitive,
      reason: reason || undefined,
      too_busy: tooBusy,
    });
  }

  async getTeam() {
    return API()
      .get('/LAR/team', { params: { lar_id: this.#id } })
      .then((res) => res.data?.team || []);
  }

  async getCongratulations() {
    return API()
      .get('/LAR/quotes/congratulations', { params: { lar_id: this.#id } })
      .then((res) => res.data);
  }

  async postCongratulations() {
    return API()
      .post('/LAR/quotes/congratulations', { id: this.#id })
      .then((res) => res.data);
  }

  async share(cc, message, sendCopyToSelf, to) {
    API()
      .post('/LAR/loan_profile/lender/share', {
        cc,
        lar_id: this.#id,
        message,
        send_copy_to_self: sendCopyToSelf,
        to,
      })
      .then((res) => res.data);
  }

  async reassign(userId, message, role = 'lender') {
    return API()
      .post('/LAR/team', {
        lar_id: this.#id,
        role_name: role === 'lender' ? 'Lender Account Executive' : 'Broker Loan Manager',
        user_id: userId,
        message,
      })
      .then((res) => res.data);
  }

  async postDDCenterInviteBorrower(data) {
    return API().post('/LAR/dd_center_invite_borrower', data);
  }

  async postPublicMessage(message, files) {
    const formData = new FormData();
    formData.set('lar_id', this.#id);
    if (files?.length > 0) {
      files.forEach((file, i) => {
        formData.set(`file_${i + 1}`, file);
      });
    }
    formData.set('message', message);

    API()
      .post('LAR/dashboard/public_message', formData)
      .then((res) => res.data);
  }

  async getPublicMessages() {
    return API()
      .get('LAR/dashboard/public_message', { params: { lar_id: this.#id } })
      .then((res) => res.data?.messages || []);
  }

  async getRatesTracker() {
    return API()
      .get('/LAR/dashboard/rates_tracker')
      .then((res) => res.data);
  }

  async hidePublicMessage(id) {
    return API()
      .delete('LAR/dashboard/public_message', { params: { message_id: id } })
      .then((res) => res.data);
  }

  async getBrokerAgreement() {
    return API().get('/LAR/broker_agreement', {
      params: {
        lar_id: this.#id,
      },
    });
  }

  async submitBrokerAgreement(data) {
    return API().post('/LAR/broker_agreement', data);
  }

  async create(larId, borrowerId, loanPurpose, loanTerm, refiLoanType, use, scenarioName, file) {
    const formData = new FormData();

    borrowerId && formData.set('borrower_id', borrowerId);
    file && formData.set('file', file);
    formData.set('loan_purpose_name', loanPurpose);
    formData.set('loan_term_name', loanTerm);
    formData.set('name', scenarioName || 'Test');
    formData.set('use', use);
    larId && formData.set('lar_id', larId);
    refiLoanType && formData.set('refi_loan_type_name', refiLoanType);

    return API()
      .post('LAR/creation', formData)
      .then((res) => res.data);
  }

  async getBids() {
    return API()
      .get('LAR/scenario', { params: { lar_id: this.#id } })
      .then(({ data }) => ({
        bids: data.bids.map((bid) => {
          let interested = null;

          if (/quote requested/i.test(bid.bid_status?.name)) {
            interested = true;
          } else if (/quote received/i.test(bid.bid_status?.name)) {
            interested = true;
          } else if (/quote sent to borrower/i.test(bid.bid_status?.name)) {
            interested = true;
          } else if (/borrower accepted/i.test(bid.bid_status?.name)) {
            interested = true;
          } else if (/not interested/i.test(bid.bid_status?.name)) {
            interested = false;
          } else if (/response/i.test(bid.bid_status?.name)) {
            interested = true;
          }

          return {
            ...bid,
            interested,
          };
        }),
        scenario: data.scenario,
      }));
  }

  async convertToLAR() {
    return API().post('LAR/scenario/convert_to_LAR', { lar_id: this.#id });
  }

  async archive(reason, additionalInfo) {
    return API().post('/LAR/to_archive', {
      lar_id: this.#id,
      reason,
      template: additionalInfo,
    });
  }

  async getArchivedData() {
    return API()
      .get('/LAR/to_archive', {
        params: {
          id: this.#id,
        },
      })
      .then((res) => res.data);
  }

  async getDelayArchiveDate() {
    return API()
      .get('/LAR/auction_settings/delay_archive', {
        params: {
          lar_id: this.#id,
        },
      })
      .then((res) => res.data);
  }

  async unarchive() {
    return API().post('/LAR/to_pre_auction', {
      lar_id: this.#id,
    });
  }

  async toggleArchiveResponse(id) {
    return API().post('LAR/scenario/bid_to_archive', { lar_id: this.#id, bid_id: id });
  }

  async requestDirectQuote(bidId, message, sendCopyToSelf) {
    return API().post('LAR/scenario/provide_quote', {
      bid_id: bidId,
      lar_id: this.#id,
      message,
      send_copy_to_self: sendCopyToSelf,
    });
  }

  async fetch() {
    return API()
      .get('/LAR/creation', { params: { lar_id: this.#id } })
      .then((res) => res.data);
  }

  async acceptQuote(id, message, sendCopyToSelf) {
    return API().post('/LAR/scenario/broker_accept_quote', {
      bid_id: id,
      lar_id: this.#id,
      message,
      send_copy_to_self: sendCopyToSelf,
    });
  }

  async sendBidViewReport(id) {
    return API().post('/LAR/view_bid', { lar_id: this.#id, bid_id: id });
  }

  async getFee() {
    return API()
      .get('/LAR/auction_settings/broker_fee', { params: { lar_id: this.#id } })
      .then((res) => res.data?.broker_fee || 0);
  }

  async setFee(fee) {
    return API().post('/LAR/auction_settings/broker_fee', { id: this.#id, broker_fee: fee });
  }

  async cancel(reason, additionalInfo) {
    return API().post('/LAR/auction_settings/cancel_auction', {
      lar_id: this.#id,
      reason,
      template: additionalInfo,
    });
  }

  async getPointsOfContact() {
    return API()
      .get('/LAR/auction_settings/point_of_contact', { params: { id: this.#id } })
      .then((res) => res.data?.items || []);
  }

  async createPointOfContact(name, email, phone, workPhone) {
    return API()
      .post('/LAR/auction_settings/point_of_contact', {
        email,
        full_name: name,
        lar_id: this.#id,
        phone,
        work_phone: workPhone,
      })
      .then((res) => res.data?.item || {});
  }

  async updatePointOfContact(id, name, email, phone, workPhone) {
    return API()
      .post('/LAR/auction_settings/point_of_contact', {
        contact_id: id,
        email,
        full_name: name,
        lar_id: this.#id,
        phone,
        work_phone: workPhone,
      })
      .then((res) => res.data?.item || {});
  }

  async deletePointOfContact(id) {
    return API().delete('/LAR/auction_settings/point_of_contact', {
      params: {
        lar_id: this.#id,
        contact_id: id,
      },
    });
  }

  async delete(notifyBorrower = false) {
    return API().post('/LAR/creation/delete', {
      lar_id: this.#id,
      notify_borrower: notifyBorrower,
    });
  }

  async getNotes() {
    return API()
      .get('/LAR/notes', { params: { lar_id: this.#id } })
      .then((res) => res.data);
  }

  async getSchedule() {
    return API()
      .get('/LAR/invite/schedule_auction', { params: { lar_id: this.#id } })
      .then(({ data }) => ({
        ...data,
        lendersMatch: data.selected,
        availableSlots: data.working_days.flatMap((day) => {
          const result = [];

          result.push({ date: day.date, slot: 'afternoon' });

          return result;
        }),
        schedule: {
          [data.auction_time.day_time]: data.auction_time,
        },
      }));
  }

  async schedule(date) {
    return API().post('/LAR/invite/schedule_auction', {
      date,
      lar_id: this.#id,
      start_now: undefined === date,
    });
  }

  async rebootSmartmatch() {
    return API().post('/LAR/invite/reboot_smartmatch', { id: this.#id });
  }

  async getFeesWorksheet() {
    return API()
      .get('/LAR/quotes/broker/fees_worksheet', { params: { lar_id: this.#id } })
      .then(({ data }) => ({
        ...data,
        ...Object.fromEntries(
          data.frees_worksheet.map(({ category, name, value, pretty_name, period, quantity }) => [
            `${crutch.find((c) => c.backend === category)?.snake || 'Other'}.${name}`,
            { pretty_name, value, period, quantity },
          ]),
        ),
        amortization: data.amortization,
        estimated_closing_day_of_month: data.estimated_closing_day_of_month,
        lender_fee: data.lender_fee,
        originator_fee: data.originator_fee,
        requesting_amount: data.requesting_amount,
      }));
  }

  async updateFeesWorksheet(data) {
    const body = {
      estimated_closing_day_of_month: data.estimated_closing_day_of_month,
      broker_fee: data.broker_fee.value,
      lar_id: this.#id,
    };

    delete data.estimated_closing_day_of_month;
    delete data.broker_fee;

    body.frees_worksheet = Object.entries(data).map(([key, fieldValue]) => {
      const [category, ...route] = key.split('.');

      return {
        category: crutch.find((c) => c.snake === category)?.backend || 'Other',
        name: route.join('.'),
        period: fieldValue.period,
        pretty_name: fieldValue.pretty_name,
        quantity: fieldValue.quantity,
        value: Number(fieldValue.value) || 0,
      };
    });

    return API().post('/LAR/quotes/broker/fees_worksheet', body);
  }

  async cancelScheduledAuction() {
    return API().delete('/LAR/invite/schedule_auction', {
      params: {
        lar_id: this.#id,
      },
    });
  }

  async acceptQuoteWithoutBorrower(bidId) {
    return API().post('/LAR/scenario/broker_accept_quote_without_borrower', {
      lar_id: this.#id,
      bid_id: bidId,
    });
  }

  async rejectQuote(bidId, reason) {
    return API().post('/LAR/scenario/broker_reject_quote', {
      lar_id: this.#id,
      bid_id: bidId,
      reason,
    });
  }

  async getActivityLogs(
    larId,
    userNames,
    userRoles,
    events,
    dateFrom,
    dateTo,
    orderBy,
    orderType,
    perPage,
    page,
    userView,
  ) {
    // date format DD/MM/Y h:MM a
    return API().get('/LAR/dashboard/PLA/activity_log', {
      params: {
        lar_id: larId,
        user_names: userNames,
        user_roles: userRoles,
        events,
        date_from: dateFrom,
        date_to: dateTo,
        order_by: orderBy,
        order_type: orderType,
        per_page: perPage,
        page,
        hide_views: userView,
      },
    });
  }

  async getActivityLogsFilters(larId) {
    return API().get('/LAR/dashboard/PLA/activity_log/filters', { params: { lar_id: larId } });
  }

  async getConversationLogs(larId, userNames, userRoles, events, dateFrom, dateTo, orderBy, orderType, perPage, page) {
    // date format DD/MM/Y h:MM a
    return API().get('/LAR/dashboard/PLA/conversation_log', {
      params: {
        lar_id: larId,
        user_names: userNames,
        user_roles: userRoles,
        events,
        date_from: dateFrom,
        date_to: dateTo,
        order_by: orderBy,
        order_type: orderType,
        per_page: perPage,
        page,
      },
    });
  }

  async getConversationLogsFilters(larId) {
    return API().get('/LAR/dashboard/PLA/conversation_log/filters', { params: { lar_id: larId } });
  }

  async sendEmailToMandate(data) {
    return API().post('/LAR/dashboard/PLA/send_email_to_mandate', data);
  }
}
