import { Form, Input, Modal, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ArchiveIcon } from 'assets/icons';
import { updateDashboardMetricsTimestamp } from 'store/actions/dashboard';

import styles from './styles/PassModal.module.css';

export default function PassModal({ API, rescind, onCancel, ...props }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  function submitForm({ reason, additionalInfo }) {
    API.pass(reason, additionalInfo).then(() => {
      onCancel?.(true);
      dispatch(updateDashboardMetricsTimestamp());
      history.push('/onboarding/scenarios');
    });
  }

  return (
    <Modal
      {...props}
      title={rescind ? 'Rescind' : 'Pass'}
      onOk={form.submit}
      okText={rescind ? 'Rescind' : 'Pass'}
      onCancel={() => onCancel?.(false)}
    >
      <Form form={form} layout="vertical" onFinish={submitForm}>
        <Form.Item label="Select the reason" name="reason" required>
          <Select
            options={[
              { value: "We've already seen this deal from another source" },
              { value: "We're too busy with other deals to review this deal" },
              { value: 'LTV and/or LTC is too high and the originator is not flexible' },
              { value: 'We requested a document/more info, but didn’t receive it' },
              { value: 'We cannot be competitive and originator is not flexible' },
              { value: "We don't understand the deal and didn’t want to ask for more clarification" },
              { value: 'Loan Amount is too large' },
              { value: 'Loan Amount is too small' },
              { value: "We don't lend on this property type" },
              { value: "We don't like or have issues with the physical property" },
              { value: 'We do not lend for this loan purpose' },
              { value: 'DSCR is too low' },
              { value: "We don't lend in this location" },
              { value: "Borrower's primary home location is outside our required area" },
              { value: "Borrower doesn't have sufficient experience or is lacking other credentials" },
              { value: 'Other reason/We don’t want to say.' },
            ]}
          />
        </Form.Item>
        <Form.Item label="Add additional information" name="additionalInfo">
          <Input.TextArea placeholder="Additional information" />
        </Form.Item>
      </Form>
      {!rescind && (
        <div className={styles.note}>
          <ArchiveIcon className={styles.icon} />
          When you select “Pass” this deal will be permanently removed and cannot be undone. Before passing, consider
          sending a message asking for more information or if they’d be interested in a different structure or loan
          terms.
        </div>
      )}
    </Modal>
  );
}
