import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd';
import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import API, { LARAPI } from '../../api';
import { CloseIcon } from '../../assets/icons';
import Modal from '../Modals/Modal';
import styles from './AgreementReviewModal.module.css';

export default function AgreementReviewModal({ open, larId, onOk, onCancel }) {
  const LAR_API = useMemo(() => new LARAPI(larId), [larId]);
  const { token } = useSelector((s) => s.user.userData);

  const sigCanvas = useRef(null);
  const [showSignature, setShowSignature] = useState(false);
  const [signature, setSignature] = useState();
  const [agreement, setAgreement] = useState('');
  const [larData, setLarData] = useState(null);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm();

  const handleShowSignature = () => setShowSignature(true);
  const handleHideSignature = () => setShowSignature(false);

  const handleClear = () => {
    sigCanvas.current?.clear();
    setSignature('');
  };

  const handleSaveSignature = async () => {
    if (sigCanvas.current) {
      setSignature(sigCanvas.current.getTrimmedCanvas());
      const dataURL = sigCanvas.current.toDataURL('image/png');

      const blob = await fetch(dataURL).then((res) => res.blob());
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      setSignature(file);
    }
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append('signature', signature);
    formData.append('lar_id', larId);
    await LAR_API.submitBrokerAgreement(formData).then(() => {
      const params = new URLSearchParams({
        lar_id: larId,
        jwt: token,
      });

      window.open(`${process.env.REACT_APP_API_URL}/LAR/broker_agreement/download?${params}`);
      onOk?.();
    });
  };

  useEffect(() => {
    if (open) {
      LAR_API.getBrokerAgreement().then((data) => {
        setAgreement(data.data);
        API()
          .get('/LAR/loan_profile/lender', { params: { id: data.data.lar_id } })
          .then((res) => setLarData(res.data));
      });
    }
  }, [open]);

  return (
    <Modal
      title="Starport Platform Purchase Order"
      okText="Submit & Continue"
      open={open}
      width={620}
      onOk={handleSubmit(onSubmit)}
      okButtonProps={{
        loading: isSubmitting,
        disabled: !signature || isSubmitting,
      }}
      onCancel={onCancel}
      className={styles.modal}
    >
      <form>
        <Divider className={classNames(styles.divider, styles.divider1)} />
        <Typography.Title level={5}>Agreement:</Typography.Title>
        <Typography.Paragraph className={styles.text}>
          Definitions are defined in the Starport Terms of Use, available at https://starportco.com/terms-conditions/
          <br />
          <br />
          As stated in the Starport Terms of Use, Paragraph 5.1, “Customer will pay all fees specified in a Platform
          Purchase Order (“PPO”). Customer agrees to pay all applicable taxes levied by any tax authority on the Service
          or Customer’s use thereof, or on any other services provided by Company.”
          <br />
          <br />
          The PPO set forth below is an agreement between you and Starport (“Starport”,”company”,”we”, or “us”).
          <br />
          <br />
          You are submitting to Starport a loan application request as defined below (‘LAR’). You agree that the final
          loan amount will be determined at the loan settlement and shall be the gross amount funded by the Capital
          Source before any fees or transaction costs are deducted.
          <br />
          <br />
          Platform License Fee: (a) When this LAR is settled by a Capital Source, you agree to pay Starport a Platform
          License Fee in an amount equal to the greater of $500 or 0.2% (20 basis points) of the final loan amount
          (‘Fee’). (b) Beginning on the 4th business day after loan settlement, you agree to pay a $100 late fee for
          each additional day in which the Fee is not received by Starport. (c ) The Fee is considered received by
          Starport at the time an electronic payment is initiated by the Customer. (d) The Fee is due and payable at the
          loan settlement. If a Capital Source does not fund and close, for any reason whatsoever, the LAR submitted by
          Customer, or if the LAR is closed or funded and then rescinded, Customer has no obligation to pay Starport for
          the Services.
          <br />
          <br />
          LAR Progress Notification: You agree to make best efforts to respond to Starport’s request for LAR progress
          updates related within 1 business day.
          <br />
          <br />
          Automatic Cancellation: This PPO is automatically canceled and voided in the event the LAR is not settled
          within 180 days from the date of this PPO.
          <br />
          <br />
          Customer is Authorized Agent: If applicable law so requires, Customer is acting as the authorized agent of
          Borrower with respect to the submission of the LAR and negotiation with Capital Sources.
          <br />
          <br />
          Customer is Licensed Agent: If applicable law so requires, Customer is stating they hold all required
          licensing as a loan officer or otherwise with respect to the origination of the mortgage in the State where
          the property is located as defined in the LAR. Non-Circumvention: (a) The Customer acknowledges that Starport
          has spent a considerable amount of time, resources, expertise, and money to design, build, and operate the
          Starport platform and to develop and maintain relationships with all of the Capital Sources that use Starport.
          (b) The Customer acknowledges that Starport will provide the names of and/or introduce them to potential
          Capital Sources invited to review the LAR (‘LAR Capital Source’). (c) The Customer agrees that, for a period
          of 12 months from the date of LAR settlement, they shall not directly or indirectly bypass, circumvent, or
          engage with any LAR Capital Source for financing without Starport’s involvement. (d) In the event that the
          Customer secures financing from a LAR Capital Source within the 12-month period, the Customer agrees to
          compensate Starport as if Starport had provided its services related to the financing, in an amount equal to
          the greater of $500 or 0.2% (20 basis points of the total loan amount funded. (e) This clause applies to any
          form of financing, refinancing, or other loan transactions secured by the Customer with the LAR Capital
          Source. Customer is an Independent Contractor: Starport and Customer acknowledge and agree, and Customer
          covenants, warrants, and represents to Starport, that Customer is an independent contractor. Nothing in the
          PPO is intended, nor shall anything in the PPO be construed, to make or deem Customer a joint venture,
          partner, representative employee, or agent of Starport; and Customer is expressly prohibited from holding
          itself out as such, nor shall Customer hold itself out at any time as the representative of Starport in any
          area, state, or jurisdiction in which Starport conducts its business. Customer is expressly prohibited from
          using Starport’s name in any advertising.
          <br />
          <br />
          Acknowledgment & Acceptance: By submitting this LAR and utilizing Starport’s services, the Customer
          acknowledges and agrees to the terms outlined in this PPO. IN WITNESS WHEREOF, the parties hereto have
          executed this Agreement as of the date of Client’s acceptance.
          <br />
          <br />
          Customer Company:
          <br />
          <br />
          By:
          <br />
          <br />
          Title:
          <br />
          <br />
          Address:
          <br />
          <br />
          Email:
          <br />
          <br />
          Phone:
        </Typography.Paragraph>
        <div className={styles.infoSection}>
          <div className={styles.info}>
            <div>Deal ID:</div>
            <div>{agreement?.lar_id}</div>
          </div>
          <div className={styles.info}>
            <div>Address:</div>
            <div>{agreement?.property_address}</div>
          </div>
          <div className={styles.info}>
            <div>Purpose:</div>
            <div>{agreement?.loan_purpose}</div>
          </div>
          <div className={styles.info}>
            <div>Property Type:</div>
            <div>{agreement?.property_type}</div>
          </div>
          <div className={styles.info}>
            <div>Borrower:</div>
            <div>{agreement?.borrower_full_name}</div>
          </div>
          <div className={styles.info}>
            <div>Requested Loan Amount:</div>
            <div>${larData?.loan_amount?.toLocaleString()}</div>
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.signWrapper}>
          <div>By:</div>
          {showSignature ? (
            <div>
              <div className={styles.signature}>
                <SignatureCanvas ref={sigCanvas} canvasProps={{ width: 443, height: 100 }} />
                {signature && <Button onClick={handleClear} className={styles.closeButton} icon={<CloseIcon />} />}
              </div>
              {!signature && (
                <Row gutter={[8, 8]}>
                  <Col>
                    <Button onClick={handleHideSignature}>Cancel</Button>
                  </Col>
                  <Col>
                    <Button type="primary" className={styles.saveButton} onClick={handleSaveSignature}>
                      Save
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          ) : (
            <Button onClick={handleShowSignature}>Add Signature</Button>
          )}
        </div>
        <Row gutter={[16, 0]}>
          <Col xs={24} md={12}>
            <div className={styles.label}>Printed Name</div>
            <Form.Item
              validateStatus={errors.printed_name && 'error'}
              help={errors.printed_name && errors.printed_name.message}
            >
              <Controller
                name="printed_name"
                control={control}
                rules={{ required: 'Please input printed name' }}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <div className={styles.label}>Title</div>
            <Form.Item validateStatus={errors.title && 'error'} help={errors.title && errors.title.message}>
              <Controller
                name="title"
                control={control}
                rules={{ required: 'Please input title' }}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <div className={styles.label}>Legal Company Name</div>
            <Form.Item
              validateStatus={errors.legal_company_name && 'error'}
              help={errors.legal_company_name && errors.legal_company_name.message}
            >
              <Controller
                name="legal_company_name"
                control={control}
                rules={{ required: 'Please input legal company name' }}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <div className={styles.label}>Street</div>
            <Form.Item validateStatus={errors.street && 'error'} help={errors.street && errors.street.message}>
              <Controller
                name="street"
                control={control}
                rules={{ required: 'Please input street' }}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <div className={styles.label}>City</div>
            <Form.Item validateStatus={errors.city && 'error'} help={errors.city && errors.city.message}>
              <Controller
                name="city"
                control={control}
                rules={{ required: 'Please input city' }}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <div className={styles.label}>State</div>
            <Form.Item validateStatus={errors.state && 'error'} help={errors.state && errors.state.message}>
              <Controller
                name="state"
                control={control}
                rules={{ required: 'Please input state' }}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <div className={styles.label}>Zip</div>
            <Form.Item validateStatus={errors.zip && 'error'} help={errors.zip && errors.zip.message}>
              <Controller
                name="zip"
                control={control}
                rules={{ required: 'Please input zip' }}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <div className={styles.label}>Phone</div>
            <Form.Item validateStatus={errors.phone && 'error'} help={errors.phone && errors.phone.message}>
              <Controller
                name="phone"
                control={control}
                rules={{ required: 'Please input phone' }}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <div className={styles.label}>Email</div>
            <Form.Item validateStatus={errors.email && 'error'} help={errors.email && errors.email.message}>
              <Controller
                name="email"
                control={control}
                rules={{ required: 'Please input email' }}
                render={({ field }) => <Input {...field} />}
              />
            </Form.Item>
          </Col>
        </Row>
      </form>
    </Modal>
  );
}
