import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { BinIcon } from 'assets/icons';
import { ReplyIcon, VerticalDotsIcon } from 'assets/icons/icons';
import parseFromET from 'constants/parseFromET';
import useOnScreen from 'hooks/useOnScreen';
import MessageItem from './MessageItem';

export default function Message({ msg, prevMsg, onMessageAction, onGetTimeTitle }) {
  const { userData, chatState } = useSelector((store) => store.user);
  const isViewOnly = chatState?.mode === 'viewOnly';

  const hasNestedMessage = msg.message_data.on_message;
  const time = moment(msg.message_data.sent_at).format('hh:mm');
  const sameUser = msg.sender.id === getUserId();
  const fullName = getFullName();
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  const { message_data } = msg;

  function getUserId() {
    const userFromToken = jwtDecode(userData.token);

    return userData.id || userFromToken?.sub || null;
  }

  const messageActions = useMemo(() => {
    const menuItems = [
      {
        icon: <ReplyIcon />,
        label: 'Reply',
        onClick: () => onMessageAction('reply', msg),
      },
      {
        icon: <ReplyIcon style={{ transform: 'rotateY(180deg)' }} />,
        label: 'Forward',
        onClick: () => onMessageAction('forward', msg),
      },
    ];

    const timePassed = moment().diff(parseFromET(msg.message_data.sent_at), 'minutes');

    if (sameUser && timePassed < 15) {
      menuItems.push({
        label: 'Delete',
        icon: <BinIcon size="1em" colour="brand-5" />,
        onClick: () => onMessageAction('delete', msg),
      });
    }

    return <Menu className="message-actions" items={menuItems} />;
  }, [msg, onMessageAction, sameUser]);

  function getFullName() {
    if (!sameUser && prevMsg?.sender.id === getUserId()) {
      return msg?.sender?.full_name;
    }

    return '';
  }

  if (isVisible) {
    onGetTimeTitle(moment(msg.message_data.sent_at).format('dddd MMM D, YYYY'));
  }

  const isMyMsg = getUserId() === msg.sender.id;

  return (
    <div className={`message-row ${isMyMsg ? 'my-message' : ''}`} ref={ref}>
      <div className="message-column">
        {fullName && <h4 className="messages-author">{fullName}</h4>}
        {hasNestedMessage && (
          <div className="message-text reply-message">
            <b>{message_data.on_message.sender.full_name}</b>
            <MessageItem
              message={message_data.on_message.message_data}
              time={time}
              sender={msg?.sender?.full_name}
              hasNestedMessage={hasNestedMessage}
            />
          </div>
        )}
        <MessageItem
          isMyMsg={isMyMsg}
          message={message_data}
          sender={msg?.sender?.full_name}
          time={time}
          hasNestedMessage={hasNestedMessage}
        />
      </div>
      {!isViewOnly && (
        <Dropdown overlay={messageActions} placement="bottom" trigger={['click']}>
          <VerticalDotsIcon />
        </Dropdown>
      )}
    </div>
  );
}
