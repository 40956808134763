import { Checkbox, Col, Form, Input, InputNumber, message, Radio, Row, Select, Space, Spin, Switch } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import API from 'api';
import { setBreadCrumbHistorySetValue } from 'store/actions/userActions';
import {
  BORROWER_CITIZENSHIP_TYPES,
  DEAL_SOURCE_TYPES,
  FICO_TYPES,
  FINANCING_AMOUNTS,
  FINANCING_TYPES,
  PAYMENT_TYPES,
  PROPERTY_TYPES,
  PURPOSE_TYPES,
  RECOURSES_TYPES,
} from './CreateEditMandate.constants';

import BreadCrumbHistory from 'components/BreadCrumbHistory';
import Button from 'components/UI/Button';
import MainLayout from 'components/Layouts/MainLayout/MainLayout';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import MandateLocations from './MandateLocations';

import styles from './CreateEditMandate.module.css';

function generateFinAmountOptions(labels) {
  return labels.map((label) => ({
    label,
    value: label.includes('m')
      ? label.includes('Greater than $100m')
        ? label
        : `${parseFloat(label.replace(/[$,]|m/g, '')) * 1000000}`
      : label.replace(/[$,]/g, ''),
  }));
}

export default function CreateEditMandate({ edit = false, view = false }) {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
  } = useForm();

  const [members, setMembers] = useState({ loading: true, data: [] });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [findCompanyData, setFindCompanyData] = useState({
    loading: false,
    data: [],
    searchValue: '',
    resultsUi: [],
  });
  const breadcrumb = [
    { preview: 'Mandates', to: { pathname: '/onboarding/mandates' } },
    {
      preview: `${data.mandate?.id ? 'Edit' : 'Create'} Mandate`,
      to: '/onboarding/mandates/create_mandate',
    },
  ];
  const companyId = Number(watch('company_id')) || history.location.state?.company_id;
  const userRole = useSelector((store) => store.user.userData?.role);
  const isSuperadmin = /^superadmin|PLA$/i.test(userRole);

  const onSubmit = (data) => {
    data.prior_revision_date = moment(data.prior_revision_date).format('YYYY-DD-MM');
    const purposeErrors = PURPOSE_TYPES.map((i, index) => {
      const name = index < 2 ? `${i.value}_ltv` : `${i.value}_ltc`;
      if (watch(i.value) && !watch(name)) {
        setError(name, {
          type: 'manual',
          message: 'Field is required',
        });
        return true;
      }
      return false;
    });

    if (purposeErrors.filter((i) => i).length) {
      return;
    }

    API()
      .post('/user_settings/mandates/lender/creation', data)
      .then(() => {
        message.info(`Mandate ${edit ? 'edited' : 'added'} successful!`);
        history.push('/onboarding/mandates');
      })
      .catch((err) => message.error(err?.response?.data?.error ?? 'Error'))
      .finally(() => setLoading(false));
  };

  const findCompany = (value) => {
    setFindCompanyData((prevState) => ({
      ...prevState,
      searchValue: value,
      loading: true,
    }));
    API()
      .get(`/user_settings/mandates/superadmin/company_search?search=${value}`)
      .then((res) => {
        const resultsUi = res.data.companies.map((company) => (
          <Select.Option key={company.id} value={company.id}>
            {company.name}
          </Select.Option>
        ));
        setFindCompanyData((prevState) => ({
          ...prevState,
          resultsUi,
          companies: res.data.companies,
          loading: false,
        }));
      });
  };

  const clearFindCompanies = () => {
    setFindCompanyData((prevState) => ({
      ...prevState,
      searchValue: '',
      resultsUi: [],
      companies: [],
    }));
    setValue('company_id', null);
  };

  const handleSearchCompany = (value) => {
    if (value) {
      findCompany(value);
    } else {
      clearFindCompanies();
    }
  };

  const handleChangeCompany = (value) => {
    setValue('company_id', value);
    const company = findCompanyData.companies.find((company) => +company.id === +value);
    setFindCompanyData((prevState) => ({
      ...prevState,
      searchValue: company.name,
    }));

    if (isSuperadmin && !edit) {
      API()
        .get('/user_settings/company/members', {
          params: {
            company_id: value,
          },
        })
        .then((res) => {
          setMembers({ loading: false, data: res.data.members });
        });
    }
  };

  const handleGoBack = (key) => dispatch(setBreadCrumbHistorySetValue(key));

  const getCompanyMembers = (company_id = null) => {
    API()
      .get(`/user_settings/company/members${company_id ? `?company_id=${company_id}` : ''}`)
      .then((res) => setMembers({ loading: false, data: res.data.members }));
  };

  function getMandateData() {
    setLoading(true);
    API()
      .get('/user_settings/mandates/lender/creation', {
        params: { id, company_id: isSuperadmin ? companyId : undefined },
      })
      .then(({ data }) => {
        const filteredData = Object.entries(data.mandate).reduce((acc, [key, value]) => {
          if (value) {
            acc[key] = value;
          }
          return acc;
        }, {});
        setData(data);
        reset({
          ...filteredData,
          LAE_assigned_id: data.mandate.LAE_assigned,
          prior_revision_date: data.mandate.prior_revision_date
            ? moment(data.mandate.prior_revision_date || new Date())
            : undefined,
          borrower_citizenship_us_citizens: true,
          company_id: isSuperadmin ? companyId : undefined,
        });
        PURPOSE_TYPES.forEach((i) => {
          setValue(i.value, !!data.mandate[`${i.value}_ltc`] || !!data.mandate[`${i.value}_ltv`]);
        });
        getCompanyMembers(data.mandate.company_id);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const handleChangePurpose = (e) => {
    const { name, checked, type } = e.target;
    if (type === 'checkbox') {
      setValue(name, checked || undefined);
      if (!checked) {
        setValue(`${name}_ltc`, null);
        setValue(`${name}_ltv`, null);
        clearErrors(`${name}_ltc`);
        clearErrors(`${name}_ltv`);
      }
    }
  };

  useEffect(() => {
    getMandateData();

    if (!edit && companyId) {
      getCompanyMembers();
    }

    // eslint-disable-next-line
  }, [companyId]);

  const validateCheckboxGroup = (fields, fieldName) => {
    const values = fields.map((field) => getValues(field.value));
    if (values.every((value) => !value)) {
      setError(fieldName, {
        type: 'manual',
        message: `Please select at least one ${fieldName.replace(/_/g, ' ')}`,
      });
    } else {
      clearErrors(fieldName);
    }
  };

  useEffect(() => {
    validateCheckboxGroup(DEAL_SOURCE_TYPES, 'deal_source');
    validateCheckboxGroup(FINANCING_TYPES, 'financing_type');
    validateCheckboxGroup(PURPOSE_TYPES, 'purpose_type');
    validateCheckboxGroup(PROPERTY_TYPES, 'property_type');
    validateCheckboxGroup(RECOURSES_TYPES, 'recourses');
    validateCheckboxGroup(PAYMENT_TYPES, 'payment');
    validateCheckboxGroup(FICO_TYPES, 'minimum_FICO');
    if (!watch('locations')?.length) {
      setError('locations', {
        type: 'manual',
        message: 'Please select at least one location',
      });
    } else {
      clearErrors('locations');
    }
  }, [Object.values(watch()).filter((i) => i).length]);

  return (
    <MainLayout title="Edit Mandate">
      <Form
        scrollToFirstError={{ behavior: 'smooth' }}
        labelCol={{ span: 4 }}
        wrapperCol={{ offset: 1 }}
        onFinish={handleSubmit(onSubmit)}
      >
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col span={24}>
            <BreadCrumbHistory history={breadcrumb} goBack={handleGoBack} historyWeb={history} separator="/" />
          </Col>
          <Col>
            <SectionTitle title="Lender Mandate" />
          </Col>
          <Col>
            <Space size={16}>
              <Button
                type="submit"
                text="Cancel"
                style={{ minWidth: '125px' }}
                onClick={() => history.push('/onboarding/mandates')}
              />
              <Button
                type="submit"
                text="Save"
                style={{ minWidth: '125px' }}
                className="btn_darkblue"
                disabled={view}
              />
            </Space>
          </Col>
          {edit && (
            <Col span={24}>
              <Space size={100}>
                <Space size={4} direction="vertical">
                  <span style={{ color: '#8C8C8C' }}>Updated on</span>
                  <span>{data.mandate?.updated_at}</span>
                </Space>
                <Space size={4} direction="vertical">
                  <span style={{ color: '#8C8C8C' }}>Updated by</span>
                  <span>{data.mandate?.updated_by}</span>
                </Space>
              </Space>
            </Col>
          )}
          <Col span={24}>
            <Spin spinning={loading}>
              <fieldset disabled={view}>
                <Form.Item
                  label="Mandate Name"
                  wrapperCol={{ span: 10, offset: 1 }}
                  validateStatus={errors.name && 'error'}
                  help={errors.name && errors.name.message}
                >
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: 'Please input name' }}
                    render={({ field }) => <Input {...field} size="large" />}
                  />
                </Form.Item>

                <Form.Item label="Prior Revision Date">
                  {moment(data?.mandate?.updated_at || new Date()).format('DD MMM YYYY')}
                </Form.Item>

                <Form.Item label="Enabled">
                  <Controller
                    name="is_active"
                    control={control}
                    render={({ field }) => <Switch {...field} checked={field.value} disabled={view} />}
                  />
                </Form.Item>

                {!(!isSuperadmin || (isSuperadmin && edit)) && (
                  <Form.Item
                    label="Select company"
                    wrapperCol={{ span: 10, offset: 1 }}
                    hidden={!isSuperadmin || (isSuperadmin && edit)}
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="Search to Select"
                      loading={findCompanyData.loading}
                      value={findCompanyData.searchValue}
                      filterOption={false}
                      defaultActiveFirstOption={false}
                      notFoundContent={null}
                      onChange={handleChangeCompany}
                      onSearch={handleSearchCompany}
                    >
                      {findCompanyData.resultsUi}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item
                  label="Assign to LAE"
                  validateStatus={errors.LAE_assigned_id && 'error'}
                  help={errors.LAE_assigned_id && errors.LAE_assigned_id.message}
                  wrapperCol={{ span: 10, offset: 1 }}
                >
                  <Controller
                    name="LAE_assigned_id"
                    control={control}
                    rules={{ required: 'Please choose Assign to LAE' }}
                    render={({ field }) => (
                      <Select {...field} loading={members.loading} style={{ maxWidth: 535 }} size="large">
                        {members.data
                          .filter((m) => m.role === 'Lender Account Executive' || m.role === 'Lender Admin')
                          .map((el, id) => (
                            <Select.Option key={`assign_lae${id}`} value={el.id} disabled={view}>
                              {el.full_name}
                            </Select.Option>
                          ))}
                      </Select>
                    )}
                  />
                </Form.Item>

                <Form.Item
                  label="Deal Source"
                  validateStatus={errors.deal_source && 'error'}
                  help={errors.deal_source && errors.deal_source.message}
                >
                  <Row gutter={20}>
                    <Col>
                      <Controller
                        name="deal_source_brokers_accepted"
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value}>
                            Brokers Accepted
                          </Checkbox>
                        )}
                      />
                    </Col>
                    <Col>
                      <Controller
                        name="deal_source_borrowers_accepted"
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value}>
                            Borrowers Accepted (DTC)
                          </Checkbox>
                        )}
                      />
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item
                  validateStatus={errors.financing_type && 'error'}
                  help={errors.financing_type && errors.financing_type.message}
                  label="Financing Type Available"
                >
                  {FINANCING_TYPES.map((i) => (
                    <Form.Item className={styles.noMarginItem}>
                      <Controller
                        name={i.value}
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value}>
                            {i.label}
                          </Checkbox>
                        )}
                      />
                    </Form.Item>
                  ))}
                </Form.Item>

                <Form.Item
                  label="Purpose"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ offset: 1 }}
                  validateStatus={errors.purpose_type && 'error'}
                  help={errors.purpose_type && errors.purpose_type.message}
                >
                  {PURPOSE_TYPES.map((i) => {
                    const isChecked = !!watch(`${i.value}_ltc`) && !!watch(`${i.value}_ltv`);

                    return (
                      <Form.Item wrapperCol={{ span: 24 }} className={styles.noMarginItem}>
                        <Row gutter={16}>
                          <Col span={10}>
                            <Controller
                              name={i.value}
                              control={control}
                              render={({ field }) => (
                                <Checkbox {...field} checked={field.value || isChecked} onChange={handleChangePurpose}>
                                  {i.label}
                                </Checkbox>
                              )}
                            />
                          </Col>
                          <>
                            <Col span={6}>
                              <Form.Item
                                className={styles.noMarginItem}
                                label="Max LTV"
                                validateStatus={errors[`${i.value}_ltv`] && 'error'}
                                help={errors[`${i.value}_ltv`] && errors[`${i.value}_ltv`].message}
                              >
                                <Controller
                                  control={control}
                                  name={`${i.value}_ltv`}
                                  render={({ field }) => (
                                    <InputNumber
                                      disabled={!watch(i.value) && !isChecked}
                                      addonAfter="%"
                                      max={100}
                                      min={0}
                                      {...field}
                                    />
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="Max LTC"
                                className={styles.noMarginItem}
                                validateStatus={errors[`${i.value}_ltc`] && 'error'}
                                help={errors[`${i.value}_ltc`] && errors[`${i.value}_ltc`].message}
                              >
                                <Controller
                                  control={control}
                                  name={`${i.value}_ltc`}
                                  render={({ field }) => (
                                    <InputNumber
                                      disabled={!watch(i.value) && !isChecked}
                                      addonAfter="%"
                                      max={100}
                                      min={0}
                                      {...field}
                                    />
                                  )}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        </Row>
                      </Form.Item>
                    );
                  })}
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 5 }}>
                  <Controller
                    name="workout_distressed_situation"
                    control={control}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value}>
                        Yes, we financing distressed/workout situations
                      </Checkbox>
                    )}
                  />
                </Form.Item>

                <Form.Item
                  label="Property type"
                  validateStatus={errors.property_type && 'error'}
                  help={errors.property_type && errors.property_type.message}
                >
                  {PROPERTY_TYPES.map((i) => (
                    <Form.Item className={styles.noMarginItem}>
                      <Controller
                        name={i.value}
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value}>
                            {i.label}
                          </Checkbox>
                        )}
                      />
                    </Form.Item>
                  ))}
                </Form.Item>

                <Form.Item label="Financing Amount">
                  <Form.Item
                    label="Minimum"
                    className={styles.formItem}
                    wrapperCol={{ span: 5 }}
                    validateStatus={errors.financing_amount_min && 'error'}
                    help={errors.financing_amount_min && errors.financing_amount_min.message}
                  >
                    <Controller
                      name="financing_amount_min"
                      control={control}
                      rules={{ required: 'Field is required' }}
                      render={({ field }) => (
                        <Select allowClear options={generateFinAmountOptions(FINANCING_AMOUNTS.min)} {...field} />
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Maximum"
                    className={styles.formItem}
                    wrapperCol={{ span: 5 }}
                    validateStatus={errors.financing_amount_max && 'error'}
                    help={errors.financing_amount_max && errors.financing_amount_max.message}
                  >
                    <Controller
                      name="financing_amount_max"
                      control={control}
                      rules={{ required: 'Field is required' }}
                      render={({ field }) => (
                        <Select allowClear options={generateFinAmountOptions(FINANCING_AMOUNTS.max)} {...field} />
                      )}
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item label="Must be Owner Occupied">
                  <Controller
                    name="owners_occupied"
                    control={control}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value}>
                        Yes, we finance owner occupied properties
                      </Checkbox>
                    )}
                  />
                </Form.Item>
                <Form.Item
                  label="Recourse"
                  validateStatus={errors.recourses && 'error'}
                  help={errors.recourses && errors.recourses.message}
                >
                  {RECOURSES_TYPES.map((i) => (
                    <Form.Item className={styles.noMarginItem}>
                      <Controller
                        name={i.value}
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value}>
                            {i.label}
                          </Checkbox>
                        )}
                      />
                    </Form.Item>
                  ))}
                </Form.Item>
                <Form.Item
                  label="Payment Type"
                  validateStatus={errors.payment && 'error'}
                  help={errors.payment && errors.payment.message}
                >
                  {PAYMENT_TYPES.map((i) => (
                    <Form.Item className={styles.noMarginItem}>
                      <Controller
                        name={i.value}
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value}>
                            {i.label}
                          </Checkbox>
                        )}
                      />
                    </Form.Item>
                  ))}
                </Form.Item>

                <Form.Item label="Borrower Citizenship">
                  <Form.Item className={styles.noMarginItem}>
                    <Checkbox checked disabled value="borrower_citizenship_us_citizen">
                      We only work with U.S. citizens
                    </Checkbox>
                  </Form.Item>
                  {BORROWER_CITIZENSHIP_TYPES.map((i) => (
                    <Form.Item className={styles.noMarginItem}>
                      <Controller
                        name={i.value}
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} checked={field.value}>
                            {i.label}
                          </Checkbox>
                        )}
                      />
                    </Form.Item>
                  ))}
                </Form.Item>
                <Form.Item
                  label="Minimum FICO"
                  name="borrower_minimum_FICO"
                  validateStatus={errors.minimum_FICO && 'error'}
                  help={errors.minimum_FICO && errors.minimum_FICO.message}
                >
                  {FICO_TYPES.map((i) => (
                    <Form.Item className={styles.noMarginItem}>
                      <Controller
                        name={i.value}
                        control={control}
                        render={({ field }) => (
                          <Radio
                            {...field}
                            checked={field.value}
                            onChange={() => {
                              FICO_TYPES.forEach(({ value }) => setValue(value, false));
                              setValue(i.value, true);
                            }}
                          >
                            {i.label}
                          </Radio>
                        )}
                      />
                    </Form.Item>
                  ))}
                </Form.Item>

                <MandateLocations
                  view={view}
                  control={control}
                  error={errors.locations}
                  locations={data.mandate?.locations}
                  availableStates={data.states}
                  onSetValue={setValue}
                />
              </fieldset>
            </Spin>
          </Col>
        </Row>
      </Form>
    </MainLayout>
  );
}
